import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import logo from './img/icon.svg';
import "./Login.css";
import { useAuth } from "./context/AuthContext";

function Login() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const [resetEmail, setResetEmail] = useState("");
  const [showResetPopup, setShowResetPopup] = useState(false);
  const { login, resetPassword } = useAuth();
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const navigate = useNavigate();

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/protected`, {
          method: 'GET',
          credentials: 'include',
        });
        if (response.ok) {
          const data = await response.json();
          await login(data.user);
          navigate('/dashboard');
        }
      } catch (err) {
        console.error('Authentication check failed:', err);
      }
    };
    checkAuth();
  }, [login, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password.length < 6) {
      setError("Password must be at least 6 characters long.");
      return;
    }

    try {
      const response = await fetch(`${API_BASE_URL}/login`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        credentials: 'include',
        body: JSON.stringify({ email: username, password }),
      });

      const data = await response.json();
      if (response.ok) {
        await login(data.user);
        navigate("/dashboard");
      } else {
        setError(data.error || "Failed to log in. Please check your credentials.");
      }
    } catch (err) {
      setError("Failed to log in. Please check your credentials.");
      console.error('Login error:', err);
    }
  };

  const handlePasswordReset = async () => {
    try {
      await resetPassword(resetEmail);
      alert('Password reset link has been sent to your email.');
      setShowResetPopup(false); // Close the popup
      setResetEmail(""); // Clear the email input
    } catch (err) {
      console.error('Password reset error:', err);
      alert('Failed to initiate password reset. Please try again.');
    }
  };

  return (
    <div className="login-container">
      <div className="login-box">
        <img src={logo} alt="Marble Connect Logo" className="logo" />
        <h2>Welcome to Marble Connect</h2>
        <p>Your AI-guided personal space</p>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label>Username</label>
            <input
              type="email"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              placeholder="Enter your email"
              required
            />
          </div>
          <div className="form-group">
            <label>Password</label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Enter your password"
              required
            />
          </div>
          {error && <p className="error-message">{error}</p>}
          <button type="submit" className="login-btn">Sign In</button>
          <button
            type="button"
            className="text-button"
            onClick={() => setShowResetPopup(true)}
          >
            Reset Password
          </button>
        </form>
      </div>

      {showResetPopup && (
        <div className="popup-overlay">
          <div className="popup-box">
            <h3>Reset Password</h3>
            <p>Enter your email address to receive a password reset link.</p>
            <input
              type="email"
              value={resetEmail}
              onChange={(e) => setResetEmail(e.target.value)}
              placeholder="Enter your email"
              required
            />
            <div className="popup-actions">
              <button onClick={handlePasswordReset} className="popup-btn">
                Send Reset Link
              </button>
              <button
                onClick={() => setShowResetPopup(false)}
                className="popup-cancel-btn"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Login;
