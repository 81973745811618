import React, { useState } from "react";
import { useUser } from "./context/UserContext";
import SharePopup from "./components/SharePopup";
import "./prompts.css";
import { Timestamp } from "firebase/firestore";

export const Prompts = () => {
  const { userData } = useUser();
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedGroup, setSelectedGroup] = useState("");
  const [textInput, setTextInput] = useState("");
  const [isFocused, setIsFocused] = useState(false);
  const [activeButton, setActiveButton] = useState("prompt");
  const [isPopupOpen, setIsPopupOpen] = useState(false); // State for popup visibility
  const [promptOptions, setPromptOptions] = useState(null); // State for storing options from the popup

  const groups = userData?.Groups || [];
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

  const filteredGroups = groups.filter((group) =>
    group.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const isPromptActive = activeButton === "prompt";
  const labelText = isPromptActive ? "Prompt:" : "Notification:";

  const handlePopupSave = (options) => {
    setPromptOptions(options); // Save the options to state
    setIsPopupOpen(false); // Close the popup
  };

  const handleSubmit = async () => {
    const clientId = selectedGroup;

    if (!clientId) {
      alert("Please select a group.");
      return;
    }

    const { scheduleSend, time, timezone } = promptOptions || {};

    const postData = {
      group: selectedGroup,
      text: textInput,
      createdAt: Timestamp.now(),
      options: promptOptions, // Include popup options
    };

    if (scheduleSend) {
      postData.scheduleTime = { time, timezone }; // Add scheduling details if applicable
    }

    try {
      const endpoint = scheduleSend
        ? "api/scheduledprompt"
        : isPromptActive
        ? "api/prompt"
        : "api/notification";

      const response = await fetch(`${API_BASE_URL}/${endpoint}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          clientId: userData.userId,
          data: postData,
        }),
      });

      const result = await response.json();

      if (result) {
        alert(
          `${scheduleSend ? "Scheduled " : ""}${
            isPromptActive ? "Prompt" : "Notification"
          } created successfully!`
        );
        setTextInput("");
      } else {
        alert(
          `Failed to create ${
            scheduleSend ? "scheduled " : ""
          }${isPromptActive ? "prompt" : "notification"}.`
        );
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div className="container">
      <div className="prompt-container">
        <button
          className={`prompt-button ${isPromptActive ? "active" : ""}`}
          onClick={() => setActiveButton("prompt")}
        >
          <span className="icon">✉️</span>
          <div className="button-text">
            <h4>New Prompt</h4>
            <p>Prompts can be shared and replied by clients</p>
          </div>
        </button>
        <button
          className={`notification-button ${!isPromptActive ? "active" : ""}`}
          onClick={() => setActiveButton("notification")}
        >
          <span className="icon">📢</span>
          <div className="button-text">
            <h4>New Announcement</h4>
            <p>Announcements are push notifications sent to clients</p>
          </div>
        </button>
      </div>

      <div className="upper-section">
        <h4>To:</h4>
        <form>
          <input
            className="text-boxes"
            id="search-bar"
            type="text"
            value={searchTerm}
            placeholder="Search or select group"
            onFocus={() => setIsFocused(true)}
            onChange={(e) => {
              setSearchTerm(e.target.value);
              setSelectedGroup("");
            }}
          />
          {isFocused && filteredGroups.length > 0 && (
            <ul className="dropdown">
              {filteredGroups.map((group, index) => (
                <li
                  key={index}
                  onMouseDown={() => {
                    setSelectedGroup(group);
                    setSearchTerm(group);
                    setIsFocused(false);
                  }}
                  className="dropdown-item"
                >
                  {group}
                </li>
              ))}
            </ul>
          )}
        </form>
      </div>

      <div className="mid-section">
        <div className="mid-row">
          <h4>{labelText}</h4>
          <button className="options" onClick={() => setIsPopupOpen(true)}>
            Options
          </button>
        </div>
        <textarea
          className="text-boxes"
          id="text-bar"
          value={textInput}
          onChange={(e) => setTextInput(e.target.value)}
        ></textarea>
      </div>

      {promptOptions && promptOptions.scheduleSend && (
        <div className="schedule-info">
          <p>
            <strong>Scheduled:</strong> {promptOptions.time} (
            {promptOptions.timezone})
          </p>
          {promptOptions.recurringPrompt && <p>Recurring: Yes</p>}
        </div>
      )}

      <button id="send-prompt" onClick={handleSubmit}>
        Send {isPromptActive ? "prompt" : "notification"}
      </button>

      {/* Popup Component */}
      <SharePopup
        isOpen={isPopupOpen}
        onClose={() => setIsPopupOpen(false)}
        onSave={handlePopupSave}
      />
    </div>
  );
};

export default Prompts;
