import React, { useState } from "react";
import "./SharePopup.css";

const timezones = [
  "UTC-12:00 (Baker Island Time)",
  "UTC-11:00 (Niue Time, Samoa Standard Time)",
  "UTC-10:00 (Hawaii-Aleutian Standard Time, Tahiti Time)",
  "UTC-09:30 (Marquesas Islands Time)",
  "UTC-09:00 (Alaska Standard Time, Gambier Islands Time)",
  "UTC-08:00 (Pacific Standard Time, Clipperton Island Time)",
  "UTC-07:00 (Mountain Standard Time)",
  "UTC-06:00 (Central Standard Time, Easter Island Time)",
  "UTC-05:00 (Eastern Standard Time, Cuba Standard Time)",
  "UTC-04:00 (Atlantic Standard Time, Bolivia Time, Paraguay Time)",
  "UTC-03:30 (Newfoundland Standard Time)",
  "UTC-03:00 (Argentina Time, Brazil Time, Uruguay Time)",
  "UTC-02:00 (South Georgia/South Sandwich Islands Time)",
  "UTC-01:00 (Azores Standard Time, Cape Verde Time)",
  "UTC±00:00 (Greenwich Mean Time, Western European Time)",
  "UTC+01:00 (Central European Time, West Africa Time)",
  "UTC+02:00 (Eastern European Time, Central Africa Time)",
  "UTC+03:00 (Moscow Standard Time, East Africa Time)",
  "UTC+03:30 (Iran Standard Time)",
  "UTC+04:00 (Gulf Standard Time, Samara Standard Time)",
  "UTC+04:30 (Afghanistan Time)",
  "UTC+05:00 (Pakistan Standard Time, Yekaterinburg Standard Time)",
  "UTC+05:30 (Indian Standard Time, Sri Lanka Time)",
  "UTC+05:45 (Nepal Time)",
  "UTC+06:00 (Bangladesh Standard Time, Bhutan Time, Omsk Standard Time)",
  "UTC+06:30 (Cocos Islands Time, Myanmar Time)",
  "UTC+07:00 (Krasnoyarsk Standard Time, Indochina Time)",
  "UTC+08:00 (China Standard Time, Australian Western Standard Time)",
  "UTC+08:45 (Australian Central Western Standard Time)",
  "UTC+09:00 (Japan Standard Time, Korea Standard Time)",
  "UTC+09:30 (Australian Central Standard Time)",
  "UTC+10:00 (Australian Eastern Standard Time, Vladivostok Standard Time)",
  "UTC+10:30 (Lord Howe Standard Time)",
  "UTC+11:00 (Magadan Standard Time, Solomon Islands Time)",
  "UTC+12:00 (Fiji Time, Kamchatka Standard Time, New Zealand Time)",
  "UTC+12:45 (Chatham Islands Time)",
  "UTC+13:00 (Tonga Time, Phoenix Islands Time)",
  "UTC+14:00 (Line Islands Time)",
];

const SharePopup = ({ isOpen, onClose, onSave }) => {
  const [scheduleSend, setScheduleSend] = useState(false);
  const [recurringPrompt, setRecurringPrompt] = useState(false);
  const [selectedTimezone, setSelectedTimezone] = useState(timezones[0]);
  const [selectedTime, setSelectedTime] = useState("19:00"); // Default time in 24-hour format
  const [selectedDate, setSelectedDate] = useState(
    new Date().toISOString().split("T")[0]
  ); // Default to today's date

  const handleSave = () => {
    onSave({
      scheduleSend,
      recurringPrompt,
      date: scheduleSend ? selectedDate : null,
      time: scheduleSend ? selectedTime : null,
      timezone: scheduleSend ? selectedTimezone : null,
    });
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="popup-overlay">
      <div className="popup-content">
        <div className="popup-header">
          <h3>Prompt Options</h3>
          <button className="close-button" onClick={onClose}>
            ×
          </button>
        </div>
        <div className="popup-body">
          {/* Schedule Send Option */}
          <div className="option">
            <input
              type="checkbox"
              id="scheduleSend"
              checked={scheduleSend}
              onChange={(e) => setScheduleSend(e.target.checked)}
            />
            <label htmlFor="scheduleSend">Schedule send</label>
          </div>
          {scheduleSend && (
            <div className="schedule-options">
              {/* Date Picker */}
              <label htmlFor="date-picker">Select Date:</label>
              <input
                id="date-picker"
                type="date"
                value={selectedDate}
                onChange={(e) => setSelectedDate(e.target.value)}
                className="date-input"
              />
              {/* Time Picker */}
              <label htmlFor="time-picker">Select Time:</label>
              <input
                id="time-picker"
                type="time"
                value={selectedTime}
                onChange={(e) => setSelectedTime(e.target.value)}
                className="time-input"
              />
              {/* Timezone Dropdown */}
              <label htmlFor="timezone-picker">Select Timezone:</label>
              <select
                id="timezone-picker"
                value={selectedTimezone}
                onChange={(e) => setSelectedTimezone(e.target.value)}
                className="timezone-dropdown"
              >
                {timezones.map((timezone, index) => (
                  <option key={index} value={timezone}>
                    {timezone}
                  </option>
                ))}
              </select>
            </div>
          )}

          {/* Recurring Prompt Option */}
          <div className="option">
            <input
              type="checkbox"
              id="recurringPrompt"
              checked={recurringPrompt}
              onChange={(e) => setRecurringPrompt(e.target.checked)}
            />
            <label htmlFor="recurringPrompt">Recurring prompt</label>
          </div>
        </div>

        {/* Footer with Save Button */}
        <div className="popup-footer">
          <button className="save-button" onClick={handleSave}>
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default SharePopup;
